const { WINPOT_CASINO_URL } = require('../../utils/consts');

export const footerNav = [
    {
        title: 'footer.nav.casino.title',
        items: [
            {
                name: 'footer.nav.casino.item_1',
                link: `${WINPOT_CASINO_URL}casino`,
            },
            {
                name: 'footer.nav.casino.item_2',
                link: `${WINPOT_CASINO_URL}live/lobby`,
            },
            {
                name: 'footer.nav.casino.item_3',
                link: `${WINPOT_CASINO_URL}casino/category/New`,
            },
            {
                name: 'footer.nav.casino.item_4',
                link: `${WINPOT_CASINO_URL}casino/category/Slots`,
            },
            {
                name: 'footer.nav.casino.item_5',
                link: `${WINPOT_CASINO_URL}casino/category/JACKPOTS`,
            },
            {
                name: 'footer.nav.casino.item_6',
                link: `${WINPOT_CASINO_URL}casino/category/Table`,
            },
            {
                name: 'footer.nav.casino.item_7',
                link: `${WINPOT_CASINO_URL}casino/category/BINGO`,
            },
        ]
    },
    {
        title: 'footer.nav.sport.title',
        items: [
            {
                name: 'footer.nav.sport.item_1',
                link: `${WINPOT_CASINO_URL}sport#/overview`,
            },
            {
                name: 'footer.nav.sport.item_2',
                link: `${WINPOT_CASINO_URL}live-sport#/live`,
            },
            {
                name: 'footer.nav.sport.item_3',
                link: `${WINPOT_CASINO_URL}info/betting-rules`,
            },
            {
                name: 'footer.nav.sport.item_4',
                link: `${WINPOT_CASINO_URL}info/betting-rules-vip`,
            },
        ]
    },
    {
        title: 'footer.nav.promo.title',
        items: [
            {
                name: 'footer.nav.promo.item_1',
                link: `${WINPOT_CASINO_URL}winclub`,
            },
            {
                name: 'footer.nav.promo.item_2',
                link: `${WINPOT_CASINO_URL}promotions?slug=promotions&tab=Casino`,
            },
            {
                name: 'footer.nav.promo.item_3',
                link: `${WINPOT_CASINO_URL}promotions?slug=promotions&tab=Sport`,
            },
            {
                name: 'footer.nav.promo.item_4',
                link: 'https://www.affiliatesworld.com/es/',
            },
        ]
    },
    {
        title: 'footer.nav.support.title',
        items: [
            {
                name: 'footer.nav.support.item_1',
                link: `${WINPOT_CASINO_URL}info/terms-and-conditions`,
            },
            {
                name: 'footer.nav.support.item_2',
                link: `${WINPOT_CASINO_URL}info/privacy-policy`,
            },
            {
                name: 'footer.nav.support.item_3',
                link: `${WINPOT_CASINO_URL}info/general-bonus-policy`,
            },
            {
                name: 'footer.nav.support.item_4',
                link: `${WINPOT_CASINO_URL}info/terms-and-conditions-vip`,
            },
            {
                name: 'footer.nav.support.item_5',
                link: 'https://faq.winpot.mx/',
            },
        ]
    },
    {
        title: 'footer.nav.info.title',
        items: [
            {
                name: 'footer.nav.info.item_1',
                link: `${WINPOT_CASINO_URL}blog`,
            },
            {
                name: 'footer.nav.info.item_2',
                link: `${WINPOT_CASINO_URL}info/responsible-gaming`,
            },
            {
                name: 'footer.nav.info.item_3',
                link: `${WINPOT_CASINO_URL}info/open-an-account`,
            },
            {
                name: 'footer.nav.info.item_4',
                link: `${WINPOT_CASINO_URL}info/deposits-to-your-account`,
            },
            {
                name: 'footer.nav.info.item_5',
                link: `${WINPOT_CASINO_URL}info/withdraw-your-profits`,
            },
            {
                name: 'footer.nav.info.item_6',
                link: `${WINPOT_CASINO_URL}info/bonus-information`,
            },
            {
                name: 'footer.nav.info.item_7',
                link: `${WINPOT_CASINO_URL}info/payment-options`,
            },
            {
                name: 'footer.nav.info.item_8',
                link: `${WINPOT_CASINO_URL}info/verifications`,
            },
            {
                name: 'footer.nav.info.item_9',
                link: `${WINPOT_CASINO_URL}/winclub/info`,
            },
        ]
    },
];

export const footerSocial = [
    {
        name: 'Facebook',
        icon: 'assets/images/facebook.svg',
        link: 'https://www.facebook.com/winpot.mx',
    },
    {
        name: 'Twitter',
        icon: 'assets/images/twitter.svg',
        link: 'https://x.com/WinpotMx',
    },
    {
        name: 'Instagram',
        icon: 'assets/images/instagram.svg',
        link: 'https://www.instagram.com/winpot.mx/',
    },
]

export const footerSocialAgents = [
    {
        name: 'Facebook',
        icon: 'assets/images/facebook.svg',
        link: 'https://www.facebook.com/profile.php?id=61561314923762',
    },
    {
        name: 'Instagram',
        icon: 'assets/images/instagram.svg',
        link: 'https://www.instagram.com/winpotangels?igsh=b2h6NWRvMGQxb2hp',
    },
    {
        name: 'TikTok',
        icon: 'assets/images/tiktok.svg',
        link: 'https://www.tiktok.com/@winpotangels?_t=8pCI5EwE6Hw&_r=1',
    },
]

export const footerPayments = [
    {
        name: 'Visa',
        icon: 'assets/images/Visa.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/visa`,
    },
    {
        name: 'Mastercard',
        icon: 'assets/images/Mastercard.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/master-card`,
    },
    {
        name: 'STP',
        icon: 'assets/images/STPMX.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/stp`,
    },
    {
        name: 'Cash',
        icon: 'assets/images/Cash.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/cash`,
    },
    {
        name: 'OXXO',
        icon: 'assets/images/OXXO.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/oxxo`,
    },
    {
        name: 'BBVA',
        icon: 'assets/images/BBVA.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/bbva`,
    },
    {
        name: 'Banorte',
        icon: 'assets/images/Banorte.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/banorte`,
    },
    {
        name: 'Farmacias Del Ahoro',
        icon: 'assets/images/Ahoro.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/farmacias-del-ahoro`,
    },
    {
        name: '7Eleven',
        icon: 'assets/images/7-eleven.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/7-eleven`,
    },
    {
        name: "Sam's Club",
        icon: 'assets/images/SamsClub.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/sam's-club`,
    },
    {
        name: 'Circle K',
        icon: 'assets/images/K-logo.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/k`,
    },
    {
        name: 'Walmart',
        icon: 'assets/images/Walmart_logo.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/walmart`,
    },
    {
        name: 'Bodega Aurrera',
        icon: 'assets/images/BodegaAurrera.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/bodega-aurrera`,
    },
    {
        name: 'Roma',
        icon: 'assets/images/Roma.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/roma`,
    },
    {
        name: 'Comercial Mexicana',
        icon: 'assets/images/ComercialMexicana.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/comercial-mexicana`,
    },
    {
        name: 'Farmacias Benavides',
        icon: 'assets/images/FarmaciasBenavides.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/farmacias-benavides`,
    },
    {
        name: 'Santa Maria',
        icon: 'assets/images/SantaMaria.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/santa-maria`,
    },
    {
        name: 'Extra',
        icon: 'assets/images/Extra_logo.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/extra`,
    },
    {
        name: 'Soriana',
        icon: 'assets/images/Soriana.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/soriana`,
    },
    {
        name: 'Calimax',
        icon: 'assets/images/Calimax.svg',
        link: `${WINPOT_CASINO_URL}info/payment-options/calimax`,
    },
]
