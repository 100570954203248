import {sendGtagEvent} from "../utils/gtag";
import {getValueFromBrowser} from "../utils/trackingQueryHook";

const footerAccordion = document.querySelector('.accordion.footer__info');
const sideMenu = document.querySelector('.sidemenu');

footerAccordion.addEventListener('click', () => {
    footerAccordion?.classList.toggle('collapsed');
});

const sidemenuAccorions = document.querySelectorAll('.sidemenu .accordion');

sidemenuAccorions.forEach((accordion) => {
    const accordionHeader = accordion.querySelector(".accordion__header");
    const dropdownCaret = accordionHeader.querySelector(".dropdown-caret");

    accordionHeader.addEventListener("click", (event) => {
        if (dropdownCaret.contains(event.target) || event.currentTarget.parentElement.getAttribute('data-exceptional-item') !== null) {
            if (!sideMenu?.classList.contains('is__open')) return;
            accordion.classList.toggle("open");
        } else {
            const href = accordionHeader.getAttribute("data-href");
            if (href) {
                const gtagEvent = accordionHeader.getAttribute("data-gtag");
                if (gtagEvent) {
                    sendGtagEvent(gtagEvent, {
                        affiliate_id: getValueFromBrowser('affiliateId') || '',
                    });
                }
                window.location.href = href;
            }
        }
    });
});
