import { sideMenuLanguages } from '../data/modules/_modules';
import getMessage from '../utils/getMessage';
import { getValueFromBrowser, removeValueFromBrowser, setValueToBrowser } from '../utils/trackingQueryHook';
import { isDeveloperMode } from '../utils/utils';

let locale = window.location.pathname.startsWith('/en') ? 'en' : 'es';

const storedLocale = getValueFromBrowser('locale');
if (storedLocale && storedLocale !== locale) {
  changeLocale(storedLocale);
}

const localeItem = document.querySelector('#languageAccordition');
const localeIcon = document.querySelector('#languageAccordition img');
const localeLabel = document.querySelector('#languageAccordition .label');
const accordionLinks = document.querySelectorAll('#languageAccordition .accordion__body a');

const localeData = sideMenuLanguages[locale];

if (!isDeveloperMode()) {
  localeItem?.remove();
}

if (localeIcon && localeLabel && accordionLinks && localeData) {
  localeIcon.src = localeData?.Icon;
  localeIcon.alt = localeData?.label;
  localeLabel.setAttribute('data-get-message', localeData?.label);

  if (localeLabel.dataset.getMessage) {
    localeLabel.innerText = getMessage(localeLabel.dataset.getMessage, localeLabel);
  }

  accordionLinks.forEach((link) => {
    const linkLocale = link.getAttribute('data-locale');
    const localeItem = sideMenuLanguages[linkLocale];

    if (localeItem) {
      link.classList.toggle('is__active', linkLocale === locale);

      const img = link.querySelector('img');
      const label = link.querySelector('.label');
      
      link.addEventListener('click', () => changeLocale(linkLocale))

      if (img) {
        img.src = localeItem?.Icon;
        img.alt = localeItem?.label;
      }
      if (label) {
        label.setAttribute('data-get-message', localeItem?.label);
      }
    }
  });
}

function changeLocale(newLocale) {
    if (newLocale === locale) return;
    const newUrl = `/${newLocale}`;

    removeValueFromBrowser('locale');
    setValueToBrowser('locale', newLocale, 30);
    window.history.pushState({ path: newUrl }, '', newUrl);
    window.location.reload();
}