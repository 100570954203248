import { removeValueFromBrowser } from "../utils/trackingQueryHook";
import { loginWithEmail } from "../utils/utils";

const userFirstNameElements = document.querySelectorAll('#userFirstName');
const checkboxRow = document.querySelector('#Login .checkbox__row');
const loginFullButton = document.querySelector('#loginFullButton');
const loginTitle = document.querySelector('#loginTitle');
const userFirstName = localStorage.getItem('firstName');
const elementsToRemove = document.querySelectorAll(`[data-login-email="${!loginWithEmail()}"]`);

elementsToRemove.forEach(element => {
    element.style.display = 'none';
});

if (!loginWithEmail()) {
    checkboxRow.classList.add("checkBoxWrapper__center");
    loginTitle.classList.add("mb_small");

    userFirstNameElements?.forEach(element => {
        element.innerText = userFirstName;
    })
}

loginFullButton?.addEventListener('click', () => {
    removeValueFromBrowser('loginWithEmail');
    location.reload();
})