const { footerNav, footerSocial, footerSocialAgents, footerPayments } = require("./footer_nav");
const { headerNav, sidemenuNav, infoSubmenu, sideMenuLanguages } = require("./header_nav");

export {
    footerNav,
    footerSocial,
    footerSocialAgents,
    footerPayments,
    headerNav,
    sidemenuNav,
    infoSubmenu,
    sideMenuLanguages,
};
